@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
    margin: 0;
    font-family: Roboto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fbfbfb;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #b8b8b8;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #888;
}
